/*
* @Author: 曹俊杰
* @Date: 2022-06-08 10:37:02
* @Module: 违约列表
*/
<template>
  <div class="box">
    <div class="title">
      我的曝光
    </div>
    <div class="main">
      <exposureList :search.sync="search" :tableData="tableData" @onSearch="onSearch" />
      <hdq-pagination small ref="pagination" @change="paginationChange" style="padding-bottom:10px;" />
    </div>
  </div>
</template>
<script>
import exposureList from "./components/exposureList"
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: {
    exposureList,
    hdqPagination
  },

  data() {
    return {
      search: '',
      tableData: [],
    };
  },
  mounted() {
    this.getExposureList()
  },
  methods: {
    onSearch() {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getExposureList()
    },
    async getExposureList() {
      const { current, size } = this.$refs.pagination;
      const keyword = this.search;
      const { status, data } = await this.$api.getExposureList({ current, size, keyword, type: 0 });
      if (status === 200) {
        const { records } = data;
        this.tableData = records;
        this.$refs.pagination.createPagination(data);
      }
    },
    paginationChange({ current, size }) {
      this.getExposureList(current, size)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/listStyle.scss";
</style>